<template>
  <v-card class="custom-shadow mt-4">
    <v-sheet class="v-offset" :color="color" elevation="4" height="90" width="90">
      <v-icon color="white" class="pa-5" size="50">{{icon}}</v-icon>
    </v-sheet>
    <v-card-text class="text-right pb-0">
      <p class="mb-2">{{title}}</p>
      <h3 class="display-1">{{value}}</h3>
      <v-divider class="my-0"></v-divider>
    </v-card-text>
    <v-card-subtitle class="caption py-2">
      <v-icon class="mr-4" small>mdi-clock</v-icon>යාවත්කාලීන කරන ලදී
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  props: ['title', 'value', 'icon', 'color']
}
</script>

<style scoped>
.v-offset {
  top: -15px;
  left: 15px;
  position: absolute;
  max-width: calc(100% - 30px);
}
</style>