<template>
  <v-container>
    <!-- <canvas ref="canvas" width="900" height="100"></canvas> -->
    <!-- Header -->
    <header class="custom-header mb-6">
      <h4>ප්‍රධාන පුවරුව</h4>
    </header>

    <v-row>
      <v-col cols="12" lg="3" sm="6">
        <StatCard :value="newAccounts" color="accent" icon="mdi-account-plus" title="නව ගිණුම්" />
      </v-col>
      <v-col cols="12" lg="3" sm="6">
        <StatCard
          :value="approvedAccounts"
          color="success"
          icon="mdi-account-check"
          title="අනුමත ගිණුම්"
        />
      </v-col>
      <v-col cols="12" lg="3" sm="6">
        <StatCard
          :value="suspendedAccounts"
          color="error"
          icon="mdi-account-cancel"
          title="අත්හිටවූ ගිණුම්"
        />
      </v-col>
      <v-col cols="12" lg="3" sm="6">
        <StatCard
          :value="totalAccounts"
          color="primary"
          icon="mdi-account-group"
          title="මුළු ගිණුම්"
        />
      </v-col>
    </v-row>

    <v-sheet class="custom-shadow pa-5">
      <p class="body-2 text--secondary">මෙම මාසය තුළ ලියාපදිංචි වීම්</p>
      <v-divider></v-divider>
      <v-row justify="center">
        <v-progress-circular v-if="!loaded" color="accent" indeterminate></v-progress-circular>
      </v-row>
      <LineChart
        :chart-data="registrations"
        :chart-labels="labels"
        style="height: 225px"
        v-if="loaded"
      ></LineChart>
    </v-sheet>
  </v-container>
</template>

<script>
import { ApiService } from "../../services/api.service";
import { mapMutations } from "vuex";
import { SHOW_ALERT, CHANGE_LOADING_STATE } from "../../store/mutation-types";
import StatCard from "../../components/StatCard";
import LineChart from "../../components/LineChart";

export default {
  components: {
    StatCard,
    LineChart
  },

  data: () => ({
    newAccounts: 0,
    approvedAccounts: 0,
    suspendedAccounts: 0,
    totalAccounts: 0,
    loaded: false,
    registrations: [],
    labels: []
  }),

  async mounted() {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    await this.fetchOverviewData();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),
    
    fetchOverviewData: async function () {
      try {
        const url = "customers/overview";
        const response = await ApiService.get(url);
        this.registrations = response.data.registrations.map(e => e.registrations);
        this.labels = response.data.registrations.map(e => e.day);
        this.newAccounts = response.data.newCustomers;
        this.approvedAccounts = response.data.approvedCustomers;
        this.suspendedAccounts = response.data.suspendedCustomers;
        this.totalAccounts = response.data.totalCustomers;
      } catch (error) {
        console.log(error);
      }
      this.loaded = true
    }
  }
}
</script>