<script>
import { Line } from 'vue-chartjs'
import { ApiService } from "../services/api.service";

export default {
  extends: Line,

  props: {
    chartData: {
      type: Array,
      required: false
    },
    chartLabels: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    options: {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            display: true
          }
        }],
        xAxes: [{
          gridLines: {
            display: false
          }
        }]
      },
      legend: {
        display: false
      },
      tooltips: {
        bodyFontFamily: 'NotoSansSinhalaRegular',
        bodyFontSize: 11
      },
      responsive: true,
      maintainAspectRatio: false,
    }
  }),

  mounted() {
    this.renderChart({
      labels: this.chartLabels,
      datasets: [
        {
          label: 'ලියාපදිංචි වූ ගණන',
          data: this.chartData,
          borderColor: '#F19953',
          borderWidth: 2,
          pointBackgroundColor: 'white',
          fill: false,
          lineTension: 0
        }
      ]
    }, this.options)
  }
}
</script>

<style>
</style>